.wrapper {
  padding: 20px 0 80px;
  display: grid;
  grid-template-columns: 820px 340px;
  grid-template-rows: auto;
  grid-gap: 40px;
  &__desc-box{
    position: relative;
  }
  &__title-inner {
    & > h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 700;
      font-size: 34px;
      line-height: 41px;
      color: #00214b;
      margin-bottom: 20px;
    }
    & > p {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #667385;
    }
  }
  &__subtitle {
    margin-bottom: 20px;
  }
  &__title {
    margin-bottom: 40px;
    font-size: 34px;
    line-height: 41px;
  }
  &__inner {
  }
  &__video-box {
    margin-bottom: 40px;
    height: 460px;
  }
  &__desc {
    h3,
    h2,
    h4,
    h5,
    h6 {
      margin: 0 !important;
      max-width: 700px;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: $titleColor;
      margin-bottom: 20px !important;
      &:first-child {
        font-weight: 700;
        font-size: 34px;
        line-height: 41px;
        color: #00214b;
        margin-bottom: 20px !important;

      }
    }
    img {
      margin-bottom: 40px;
    }
    & > ul {
      padding: 0;
      padding-left: 20px;
      li {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 19px;
        color: #667385;
      }
    }
    & > p {
      max-width: 700px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $grayColor;
      margin-bottom: 40px !important;
    }
    & > img {
      border-radius: 10px;
      display: block;
      max-width: 820px;
    }
    & > figure {
      margin-bottom: 40px;
    }
    & > ul {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $grayColor;
      margin-bottom: 40px;
    }
  }
}

.tabs {
  border-bottom: 1px solid $blueColor;
  margin-bottom: 40px;
  ul {
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $grayColor;
  }
  li {
    cursor: pointer;
    padding: 5px 0;
  }
  li.active {
    color: $titleColor;
    font-weight: 500;
  }
  li + li {
    margin-left: 27px;
  }
}
.aside {
  padding-top: 55px;
  &__title {
    margin-bottom: 43px;
  }
  &__order{
    // box-shadow: 0px 10px 10px rgb(0 0 0 / 10%);
    display: flex;
    padding-top: 40px;
    transition: all 0.3s ease;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .btn{
      border-radius: 20px;

      margin-top: 20px;
    }
  }
}
