.settingsPage{
    &__box{
        margin-bottom: 20px;
    }
    &__course{
        max-width: 200px;
        span{
            display: inline-block;
            padding: 2px;
            font-weight: bold;
            font-size: 12px;
            line-height: 1.1;
            border: 1px solid #000;
            margin-right: 2px;
            margin-bottom: 1px;
        }
    }
}