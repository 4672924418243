.mycourse {
  padding: 100px 0;
  color: #00214b;
  &__inner {
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
}
.mycourse__item {
  display: flex;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  margin-bottom: 30px;
  &-box {
    flex: 1;
    padding: 5px;
  }
  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1em;
    color: #00214b;
    margin-bottom: 10px;
  }
  &-img {
    margin-right: 20px;
    border-radius: 10px;
    object-fit: cover;
    width: 140px;
    height: 100px;
  }
  &-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  &-progress {
    flex: 1;
    width: 100%;
    height: 10px;
    margin-right: 5px;
    border-radius: 10px;
    background-color: #ccc;
    overflow: hidden;
  }
  &-width {
    width: 0;
    display: block;
    height: 10px;
    background-color: #55fe06;
  }
  &-btn {
    color: #fff;
    border-radius: 3px;
    padding: 2px;
    background-color: #00bcf1;
  }
}

@media (max-width: 450px) {
  .mycourse__item {
    flex-direction: column;
    &-img {
      height: auto;
      width: 100%;
    }
    &-box{
        padding: 10px;
    }
    &-btn{
        display: block;
        text-align: center;
    }
  }
}
