html {
  scroll-behavior: smooth;
}

body {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.main {
  flex: 1;
}
img {
  display: block;
  height: auto;
  max-width: 100%;
}
.container {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
}
a {
  text-decoration: none;
  color: inherit;
}

.btn {
  position: relative;
  display: inline-flex;
  background: $blueColor;
  white-space: nowrap;
  box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  color: $white;
  padding: 16px 40px;
  max-width: max-content;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    color: $white;
    opacity: 0.9;
  }
  &:active {
    transform: scale(0.9);
  }
  &:target {
    transform: scale(0.9);
  }
  &:disabled {
    cursor: initial;
    opacity: 0.9;
    &:active {
      transform: scale(1);
    }
    &:target {
      transform: scale(1);
    }
  }
}

.title {
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  color: $titleColor;
}

.subtitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: $blueColor;
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: $grayColor;
}

%ellipse {
  display: -webkit-box !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
%center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo{
  img{
    height: 60px;
  }
}

.logo__img{
  height: 60px;
}