.card {
  display: grid;
  grid-template-columns: repeat(4, 285px);
  grid-gap: 20px;
  &__image-box {
    img {
      border-radius: 10px;
      object-fit: cover;
      width: 288px;
      height: 184px;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    width: 285px;
    background: $white;
    border: 1px solid #efeeef;
    border-radius: 10px;
    padding-bottom: 13px;
    transition: all 0.3s ease-in-out;
    &:hover {
      box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.1);
    }
  }
  &__desc-box {
    padding: 11px 12px 26px 12px;
  }
  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $titleColor;
    margin-bottom: 10px;
    height: 34px;
    @extend %ellipse;
  }
  &__desc {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #8296b0;
    height: 34px;
    @extend %ellipse;
  }
  &__time-box,
  &__price-box {
    padding: 0 12px;
    display: flex;
    img {
      display: inline-block;
    }
  }
  &__time-box {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #a5a5a4;
    padding-bottom: 19px;
    border-bottom: 1px solid #efeeef;
    margin-bottom: 16px;
  }

  &__price {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $titleColor;
  }
  &__time,
  &__lessons,
  &__rating,
  &__user {
    display: flex;
    align-items: center;
  }
  &__time,
  &__lessons {
    margin-right: 10px;
    img {
      margin-right: 7px;
    }
  }
  &__rating,
  &__user {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: $titleColor;
    img {
      margin-right: 2px;
    }
  }
  &__rating {
    margin-left: auto;
    margin-right: 11px;
  }
}
