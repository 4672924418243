.rating {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  &__star {
    display: flex;
    align-items: center;
    margin-right: 60px;
  }
  &__num {
    font-weight: 500;
    font-size: 34px;
    line-height: 41px;
    color: #232323;
    margin-right: 20px;
  }

  &__box {
    display: flex;
  }
  &__user,
  &__rating {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #00214b;
    display: flex;
    margin-right: 20px;
    img {
      margin-right: 10px;
    }
  }
}

@media (max-width: 540px) {
  .rating {
    flex-direction: column;
    align-items: flex-start;
    &__star {
      margin-bottom: 20px;
    }
  }
}
