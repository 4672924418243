@import "vars";
@import "reset";
@import "global";

/* components */
@import "components";

/* pages */
@import "pages/home";
@import "pages/course";
@import "pages/register";
@import "pages/notfound";
@import "pages/about";
@import "pages/contact";
@import "pages/mycourse";
/* admin */
.quote {
  p {
    color: #667385;
  }
}
/* media queries */
@import "media";
