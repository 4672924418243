.test {
  &__start {
    padding-top: 50px;
    text-align: center;
  }
  h3,
  h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: $titleColor;
    margin-bottom: 10px;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $titleColor;
    margin-bottom: 40px;
  }
  
  &__box-desc {
    margin-bottom: 40px;
  }

  &__result {
  }

  &__result-box {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }
  &__result-range {
    width: 400px;
    margin-right: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #ccc;
    overflow: hidden;
  }
  &__result-range-item {
    height: 100%;
    border-radius: 10px;
    width: 80%;
    background-color: #56fe06;
  }

  label {
    position: relative;
    display: block;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: $grayColor;
    margin-bottom: 20px;
    padding-left: 25px;
  }
  ol {
    margin-bottom: 40px;
    padding-left: 20px;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  input:checked + .checkmark {
    border: 1px solid $blueColor;
    &::after {
      background-color: $blueColor;
    }
    & + .checkmark__text {
      color: $blueColor;
    }
    &.true {
      border-color: #56fe06;
    }
    &.true::after {
      background-color: #56fe06;
    }
    & + .checkmark__text.true {
      color: #56fe06;
    }
    &.wrong {
      border-color: #ff0000;
    }
    &.wrong::after {
      background-color: #ff0000;
    }
    & + .checkmark__text.wrong {
      color: #ff0000;
    }
  }
  .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 14px;
    width: 14px;
    border: 1px solid #667385;
    border-radius: 50%;
    &::after {
      position: absolute;
      content: "";
      width: 9px;
      height: 9px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
