html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

// ul[class],
// ol[class] {
//   padding: 0;
//   list-style: none;
// }

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}
a {
  text-decoration: none;
  color: inherit;
}
