.top {
  background-color: #70e6e8;
  // background-image: url("../../../src/assets/top-bg.svg");
  background-repeat: no-repeat;
  background-position: top -80px right;
  background-size: contain;
  &__inner {
    padding-top: 77px;
    display: flex;
    justify-content: space-between;
  }
  &__about {
    padding-top: 30px;
    max-width: 592px;
  }
  &__subtitle {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: $blueColor;
    margin-bottom: 10px;
  }
  &__title {
    font-weight: 700;
    font-size: 60px;
    line-height: 60px;
    color: $titleColor;
    margin-bottom: 20px;
  }
  &__desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #3e3a71;
    margin-bottom: 40px;
  }
  &__btn {
    margin-bottom: 40px;
  }

  &__app {
    display: inline-flex;
    padding: 10px 20px;
    background: #000000;
    box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
  }

  &__img {
    max-width: 619px;
    margin-right: -40px;
  }
}

.popular {
  padding: 80px 0;
  &__subtitle {
    text-align: center;
    margin-bottom: 10px;
  }
  &__title {
    text-align: center;
    margin-bottom: 40px;
  
  }
  .card {
    margin-bottom: 80px;
  }
  &__btn {
    display: block;
    margin: 0 auto;
  }
}

.why {
  padding: 80px 0 270px;
  background-color: #f1fdff;
  // background-image: url("../../../src/assets/why-bg.svg");

  background-repeat: no-repeat;
  background-position: left top 149px;
  background-size: contain;
  &__inner {
    display: flex;
    justify-content: space-between;
  }
  &__desc-box {
    max-width: 305px;
  }
  &__subtitle {
    margin-bottom: 18px;
  }
  &__title {
    margin-bottom: 10px;
  }
  &__cards {
    display: grid;
    grid-template-columns: repeat(3, 259px);
    grid-gap: 40px;
    grid-template-rows: max-content;
  }
  &__card-item {
    background: #f9f9f9;
    box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.1);
    border-radius: 14px;
    padding: 22px 36px;
    text-align: center;
  }
  &__card-image-box {
    width: 58px;
    height: 58px;
    background: $blueColor;
    border-radius: 50%;
    margin: 0 auto;
    @extend %center;
    margin-bottom: 25px;
  }
  &__card-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: $titleColor;
    margin-bottom: 20px;
  }
  &__card-desc {
    font-size: 14px;
  }
}

.certificate {
  padding: 60px 0 150px;
  &__inner {
    display: flex;
    justify-content: space-between;
  }
  &__desc-box {
    max-width: 462px;
    padding-top: 80px;
  }
  &__subtitle {
    margin-bottom: 10px;
  }
  &__title {
    margin-bottom: 20px;
  }
  &__desc {
    margin-bottom: 20px;
  }
}

.advantages {
  padding: 60px 0;
  background-color: $titleColor;
  color: $white;
  &__inner {
    display: flex;
    justify-content: space-between;
  }
  &__title {
    position: relative;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 11px;
    padding-left: 54px;
    &::before {
      content: "";
      position: absolute;
      width: 1.25em;
      height: 1.25em;
      background-image: url("../../../src/assets/icons/check-advantages.svg");
      background-size: cover;
      background-repeat: no-repeat;
      left: 0;
    }
  }
  &__desc {
    padding-left: 54px;
    font-size: 14px;
  }
}

.prepare {
  padding: 180px 0 80px;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__desc-box {
    width: 551px;
  }
  &__subtitle {
    margin-bottom: 10px;
  }
  &__title {
    margin-bottom: 20px;

  }
  &__desc {
    margin-bottom: 40px;
    max-width: 452px;
  }
  &__lists {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #3e3a71;
  }
  &__list {
    max-width: 246px;
    padding: 0;
    list-style: none;
  }
  &__list-item {
    position: relative;
    padding-left: 23px;
    margin-bottom: 20px;
    &::before {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      border: 4px solid #00214b;
      left: 0;
      top: 5px;
    }
  }
}

.students {
  padding: 80px 0;
  &__inner {
    display: flex;
    justify-content: space-between;
  }
  &__video-box {
    width: 592px;
    height: 333px;
    margin-right: 20px;
  }
  &__desc-box {
    max-width: 377px;
    text-align: center;
  }
  &__subtitle {
    margin-bottom: 10px;
  }
  &__title {
    margin-bottom: 20px;
  }
}

.app {
  background-color: #f1fdff;
  padding: 80px 0;
  &__inner {
    display: flex;
    align-items: center;
  }
  &__img {
    max-width: 460px;
    margin-right: 160px;
  }
  &__desc-box {
    max-width: 483px;
  }
  &__subtitle {
    margin-bottom: 10px;
  }
  &__title {
    margin-bottom: 20px;
  }
  &__desc {
    margin-bottom: 40px;
  }
  &__item {
    margin-top: 40px;
  }
}

.pencil {
  position: relative;
  height: 380px;
  //background-image: url("../../../src/assets/pencil.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  &::after {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  }
}
