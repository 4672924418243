.navaccordion__item {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 48px;
  border-radius: 10px;
  margin-bottom: 12px;
  padding-left: 15px;
  padding-right: 15px;
  svg {
    margin-right: 15px;
  }
  text-transform: capitalize;
  &.active {
    background: #0832de;
    color: #fff;
    svg {
      path {
        stroke: #fff;
      }
    }
  }
  .icon {
    margin-right: 0;
    margin-left: auto;
    transform: rotate(-90deg);
    transition: all 0.3s ease;
    path {
      stroke: #343434;
    }
  }
  &.active {
    .icon {
      transform: rotate(0);
    }
  }
}
.navaccordion {
  &__children {
    display: none;
    transition: all 0.3s ease-in;
    max-height: 0;
    opacity: 0;
  }
  &__item.active + &__children {
    display: block;
    opacity: 1;
    max-height: 100%;
  }
  &__link {
    height: 48px;
    display: flex;
    align-items: center;
    margin-left: 20px;
    padding-left: 15px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #343434;
    &.active{
      background: #CED6F8;
      border-radius: 10px;
    }
  }
}
