.studentsPage {
  &__box {
    display: inline-block;
    margin-bottom: 20px;
  }
  &__box-item {
    text-align: center;
  }
  &__box-num {
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #343434;
    margin-right: 5px;
  }
  &__box-name {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #c4c4c4;
  }
}
