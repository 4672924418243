.accordion {
  max-height: 60px;
  background: #ffffff;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  &__content {
    & > .accordion {
      padding-left: 10px;
    }
  }

  // &__item{
  //   opacity: 0;
  //   max-height: 0;
  // }
  &__label {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
    color: $titleColor;
    cursor: pointer;
  }
  &__label-img {
    transform: rotate(0);
    transition: transform 0.3s ease;
  }
  &__content {
    display: none;
    max-height: 0;
    opacity: 0;
    background-color: #fff;
    transition: all 0.3s ease-out;
  }
  &.active {
    max-height: 100%;
    & > .accordion__content {
      display: block;
      max-height: 100%;
      opacity: 1;
    }
    & > .accordion__label .accordion__label-img {
      transform: rotate(90deg);
    }
  }
  &__item {

    padding: 12px 24px 12px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.active {
      background-color: #00bcf1;
      color: #fff;
      svg {
        path {
          fill: #fff;
        }
      }
      cursor: pointer;
    }
  }
  &__item-box {
    display: flex;
    svg {
      margin-right: 10px;
      width: 14px;
      height: 14px;
    }
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
  }
  &__item-time {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
}
