.add {
  position: relative;
  h3 {
    margin-bottom: 20px;
  }
  .spinner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba( #000000, 0.01);
    z-index: 5;
  }
}
