@import "sidebar";
@import "navaccordion";

/* pages */
@import "pages/general";
@import "pages/students";
@import "pages/addcourse";
@import "pages/lessonAdd";
@import "pages/billing";
@import "pages/settings";
@import "pages/admins";
.admin {
  display: grid;
  grid-template-columns: 2fr 10fr;
  &__main {
    position: relative;
    min-height: 100vh;
    padding: 35px 20px;
  }
}
