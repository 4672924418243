.comments {
  padding-top: 100px;
  pre {
    font-family: sans-serif;
  }
  img {
    margin: 0 !important;
  }
  &__send {
    padding: 38px 20px;
    border: 1px solid #a5a5a4;
    border-radius: 8px;
    input {
      outline: none;
      background-color: transparent;
      border: none;
      margin-right: 20px;
      width: 100%;
    }
    button {
      flex-shrink: 0;
      background-color: $blueColor;
      border: 0;
      border-radius: 50%;
      padding: 0;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0;
      margin-left: auto;
      justify-self: flex-end;
    }
  }
  &__rating {
    margin-bottom: 20px;
  }
  &__box {
    display: flex;
    align-items: center;
    img {
      margin-bottom: 0;
    }
  }
  &__item {
    padding-top: 20px;
    padding-left: 20px;
    display: flex;
    align-items: flex-start;
  }
  &__item + .comments__item {
    margin-top: 20px;

    border-top: 1px solid #232323;
  }
  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #d9d9d9;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: $titleColor;
    margin-right: 20px;
  }
  &__desc {
    max-width: 640px;
  }
  &__name {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #232323;
    margin-bottom: 8px;
  }
  &__date {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $grayColor;
    margin-bottom: 13px;
  }
  &__text {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #232323;
  }
  .btn {
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    white-space: nowrap;
  }
}
@media (max-width: 620px) {
  .comments {
    &__text {
      font-size: 16px;
      line-height: 19px;
    }
    &__avatar {
      width: 40px;
      height: 40px;
      font-size: 20px;
    }
    &__btn {
      width: 40px;
      height: 40px;
    }
  }
}
