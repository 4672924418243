@media (max-width: 1300px) {
  .top {
    &__img {
      margin-right: 0;
    }
  }
  .popular {
    .card {
      margin-bottom: 30px;
    }
  }
  .card {
    grid-template-columns: repeat(auto-fill, minmax(285px, 300px));
    justify-content: center;
  }
  .why {
    &__inner {
      flex-wrap: wrap;
      justify-content: center;
    }
    &__desc-box {
      max-width: 100%;
      text-align: center;
      margin-bottom: 40px;
    }
    &__text {
      max-width: 700px;
    }
  }
  .certificate {
    &__inner {
      align-items: flex-start;
    }
    .image-box {
      font-size: 15px;
    }
  }
  .prepare {
    padding: 80px 0;
    &__inner {
      align-items: center;
    }
    .image-box {
      font-size: 15px;
    }
  }
  .wrapper {
    grid-template-columns: 680px 340px;
    &__desc > img {
      width: 100%;
    }
  }
  .students {
    padding-inline: 30px;
  }
}

@media (max-width: 1100px) {
  .certificate {
    .image-box {
      font-size: 12px;
    }
    &__desc-box {
      padding-top: 20px;
    }
  }
  .prepare {
    .image-box {
      font-size: 12px;
    }
    &__desc-box {
      padding-top: 20px;
    }
  }
  .app {
    &__img {
      margin-right: 30px;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 1040px) {
  .header {
    // &__signup {
    //   margin-left: 20px;
    //   width: 100%;
    //   border: none;
    //   outline: none;
    // }
  }
  .nav {
    &__list-item + li {
      margin-left: 20px;
    }
  }
  .top {
    &__inner {
      padding-top: 0;
      align-items: flex-end;
    }
    &__about {
      padding-bottom: 30px;
    }
    &__img {
      max-width: 400px;
    }
  }
  .prepare {
    &__desc-box {
      width: 100%;
    }
    &__title {
      max-width: 100%;
    }
    &__desc {
      margin-bottom: 20px;
      max-width: 100%;
    }
    &__lists {
      justify-content: flex-start;
    }
  }
}
@media (max-width: 980px) {
  .top {
    &__about {
      align-self: flex-start;
    }
    &__title {
      font-size: 45px;
      line-height: 47px;
      margin-bottom: 10px;
    }
    &__desc {
      margin-bottom: 20px;
    }
    &__btn {
      margin-bottom: 20px;
    }
    &__img {
      padding-top: 30px;
    }
  }
  .certificate {
    .image-box {
      font-size: 9px;
    }
  }
  .prepare {
    .image-box {
      font-size: 9px;
    }
  }
  .advantages {
    padding: 30px 0;
    &__inner {
      flex-wrap: wrap;
      justify-content: center;
    }
    &__item {
      margin-bottom: 20px;
      max-width: 300px;
    }
  }
}
@media (max-width: 880px) {
  .header {
    &__inner {
      justify-content: space-between;
      position: relative;
    }
    &__login {
      display: block;
      padding: 10px 100px;
    }
    &__signup {
      display: block;
      margin-left: 0;
      padding: 10px 100px;
      width: 100%;
    }
  }
  .nav {
    box-shadow: -15px 4px 10px -3px rgba(69, 94, 112, 0.05);
    position: fixed;
    display: block;
    padding-top: 80px;
    padding-left: 10px;
    padding-right: 10px;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    background-color: #fff;
    transform: translateX(1000px);
    transition: 0.3s;
    //  background-color: rgb(122, 39, 39);
    text-align: center;
    &__list {
      display: block;
    }
    &__list-item {
      display: block;
    }
    &__list-item + li {
      margin-left: 0;
    }
    &__list-link {
      display: block;
      padding: 10px 100px;
    }
    &__overlay {
      display: none;
      position: fixed;
      background-color: rgba($color: #000000, $alpha: 0.5);
      opacity: 0;
      transition: 0.4s;
    }
  }
  .nav.active {
    transform: translate(0);
    & + .nav__overlay {
      display: block;
      opacity: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }
  .menu {
    display: flex;
    z-index: 10;
  }
  .why {
    padding-bottom: 100px;
    &__cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    &__card-item {
      max-width: 259px;
      min-width: 259px;
    }
  }
  .certificate {
    padding-bottom: 40px;
    &__inner {
      flex-direction: column;
    }
    &__desc-box {
      order: 1;
      max-width: 100%;
      margin-bottom: 20px;
    }
    .image-box {
      order: 2;
      align-self: center;
    }
  }
  .prepare {
    padding-bottom: 40px;
    &__inner {
      flex-direction: column;
    }
    &__desc-box {
      order: 1;
      max-width: 100%;
      margin-bottom: 20px;
    }
    .image-box {
      order: 2;
      align-self: center;
    }
  }
  .students {
    &__inner {
      flex-direction: column;
      align-items: center;
    }
    &__video-box {
      margin-right: 0;
      margin-bottom: 20px;
      width: 100%;
    }
  }
  .app {
    &__img {
      max-width: 300px;
    }
  }
}

@media (max-width: 790px) {
  .top {
    &__title {
      font-size: 35px;
      line-height: 38px;
    }
    &__img {
      max-width: 300px;
    }
  }
  .app {
    &__inner {
      flex-direction: row-reverse;
    }
    &__img {
      margin-right: 0;
    }
  }
  .footer {
    &__main {
      margin-right: 10px;
    }
    &__list + ul {
      margin-left: 30px;
    }
  }
}
@media (max-width: 680px) {
  .title {
    font-size: 35px;
    line-height: 1.2;
  }
  .popular {
    padding: 45px 0;
  }
  .app {
    &__inner {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
    &__desc-box {
      margin-bottom: 20px;
      max-width: 100%;
    }
    &__img {
      align-self: center;
    }
  }
  .pencil {
    height: 300px;
  }
  .footer {
    &__inner {
      flex-direction: column;
    }
    &__main {
      margin-right: 10px;
    }
    &__list + ul {
      margin-left: 20px;
    }
  }
  .wrapper {
    &__video-box {
      height: 300px;
    }
  }
}
@media (max-width: 620px) {
  .top {
    &__about {
      max-width: 450px;
    }
    &__img {
      display: none;
    }
  }
  .students {
    padding-inline: 0;

    &__video-box {
      width: 100%;
      height: 250px;
    }
    &__desc-box {
      max-width: 280px;
      padding: 20px;
      text-align: center;
    }
  }
  .course {
    &__info-box {
      display: block;
    }
    &__info-item {
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 450px) {
  .certificate {
    .image-box {
      font-size: 8px;
    }
  }
  .prepare {
    padding: 40px 0;
    .image-box {
      font-size: 8px;
    }
  }
  .pencil {
    height: 250px;
    background-position: left center;
    background-size: cover;
  }
  .footer {
    &__links {
      flex-direction: column;
    }
    &__list + ul {
      margin-left: 0;
    }
  }
  .wrapper {
    &__video-box {
      height: 250px;
    }
  }
}
