.contact {
  padding: 100px 0;
  &__inner {
    display: flex;
    justify-content: space-between;
  }
  &__title {
    color: $titleColor;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 10px;
  }
  .text {
    margin-bottom: 20px;
  }
  .map {
    width: 600px;
    height: 450px;
  }
}

@media (max-width: 1060px) {
  .contact {
    .map {
      width: 500px;
      height: 300px;
    }
  }
}

@media (max-width: 620px) {
  .contact {
    &__inner {
      flex-direction: column;
    }
    .map {
      width: 100%;
      height: 300px;
    }
  }
}
