/* public components */
@import "components/header";
@import "components/footer";
@import "components/card";
@import "components/slider";
@import "components/courseInfo";
@import "components/accordion";
@import "components/rating";
@import "components/comments";
@import "components/test";
@import "components/arrow";
@import "components/spinner";
.image-box {
  font-size: 16px;
  position: relative;
  padding-right: 6.375em;
  padding-bottom: 9.375em;
  max-width: 36.875em;
  &-img-1 {
    box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    max-width: 30.5em;
  }
  &-img-2 {
    position: absolute;
    max-width: 19.0625em;
    border: 6px solid #ffffff;
    border-radius: 0px 10px 10px 10px;
    right: 0;
    bottom: 0;
  }
}
.tabs__list{
  padding: 0;
  list-style: none;
}
