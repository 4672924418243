.about{
    padding: 80px 0;
    .subtitle{
        margin-bottom: 10px;
        text-align: center;
    }
    .title{
        margin-bottom: 40px;
        text-align: center;
    }
}