.register {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 460px;
  height: 100vh;
  &__desc-box {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-right: 150px;
    padding-bottom: 200px;
  }
  &__desc {
    color: $white;
    max-width: 420px;
    h2 {
      font-weight: 500;
      font-size: 48px;
      line-height: 58px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
  }
  &__enter {
    padding: 0 45px;
    display: flex;
    flex-direction: column;
  }
  .logo {
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
  &__sign {
    flex: 1;
    padding-bottom: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .sign {
    h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-transform: uppercase;
      color: $blueColor;
      margin-bottom: 40px;
    }
    .input {
      position: relative;
      img {
        position: absolute;
        left: 3px;
        top: 12px;
      }
      margin-bottom: 20px;
    }
    input {
      width: 100%;
      padding: 12px 12px 12px 40px;
      background-color: transparent;
      border: none;
      outline: none;
      border-bottom: 1px solid #667385;

      color: #667385;

      &::placeholder {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
        color: #667385;
      }
    }
    button {
      display: flex;
      height: 48px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 100%;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      color: #ffffff;
      background-color: $blueColor;
      border: none;
      border-radius: 100px;
    }
  }
  &__enter {
    position: relative;
  }
  &__nav {
    list-style: none;
    padding-left: 0;
    font-size: 16px;
    line-height: 19px;
    position: absolute;
    top: 68px;
    left: -181px;
    text-align: right;
  }
  &__nav-link {
    display: block;
    font-weight: 500;

    text-transform: uppercase;
    color: #ffffff;
    padding: 10px 34px;
    background-color: transparent;
    margin-bottom: 20px;
    border-radius: 10px 0px 0px 10px;
    &.active {
      color: $titleColor;
      background-color: #ffffff;
    }
  }
}

@media (max-width: 1150px) {
  .register {
    grid-template-columns: 1fr 360px;
    &__desc-box {
      padding: 10px;
    }
  }
}

@media (max-width: 880px) {
  .register {
    &__desc {
      h2 {
        font-size: 34px;
        line-height: 40px;
      }
    }
  }
}
@media (max-width: 700px) {
  .register {
    &__desc {
      display: none;
    }
  }
}

@media (max-width: 620px) {
  .register {
    &__sign {
      justify-content: flex-start;
    }
    &__nav {
      text-align: left;
      position: initial;
      color: $titleColor;
    }
    &__nav-link {
      color: $titleColor;
      margin-bottom: 10px;
      padding-left: 45px;
      transform: translateX(-45px);
      border-radius: 0px 10px 10px 0px;
      &.active {
        background-color: $blueColor;
        color: $white;
      }
    }
  }
}
@media (max-width: 450px) {
.register{
  display: block;
  &__desc-box{
    display: none;
  }
  .sign {
    h3{
      margin-bottom: 30px;
    }
  }
}
}