.lesson {
  h3 {
    margin-top: 5px;
    margin-bottom: 20px;
  }
  &__info {
    padding-top: 20px;
    max-width: 500px;
  }
  .accordion__item-box{
    display: flex;
    align-items: center;
  }
}
